import React from "react";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import alertKnockoutToken from "@amzn/meridian-tokens/base/icon/alert-knockout";
import thumbsUpToken from "@amzn/meridian-tokens/base/icon/thumbs-up";
import {
  CALM_CODE_SUBMIT_SUCCESS_STATUS,
  CALM_CODE_SUBMIT_ERROR_MESSAGE,
  CALM_CODE_PROCESSING_MESSAGE, ALERT_AUTHENTICATION_MESSAGE, INVALID_MESSAGE,
} from "../../constants";

export default function CalmCodeSubmitModal({
  responseMessage,
  open,
  onClose,
}) {
  let shortText = "";

  switch (responseMessage) {
    case CALM_CODE_SUBMIT_SUCCESS_STATUS:
      shortText = "Success!";
      break;
    case CALM_CODE_PROCESSING_MESSAGE:
      shortText = CALM_CODE_PROCESSING_MESSAGE;
      break;
    case ALERT_AUTHENTICATION_MESSAGE:
      shortText = ALERT_AUTHENTICATION_MESSAGE;
      break;
    default:
      if (responseMessage?.includes(INVALID_MESSAGE)) {
        shortText = responseMessage;
      } else {
        shortText = CALM_CODE_SUBMIT_ERROR_MESSAGE;
      }
      break;
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        title=""
        onClose={onClose}
        scrollContainer="viewport"
        width={300}
        closeLabel="Close"
        data-type="modalBox"
      >
        <Text id="modal-description">
          {responseMessage === CALM_CODE_SUBMIT_SUCCESS_STATUS ? (
            <Icon tokens={thumbsUpToken} />
          ) : (
            <Icon tokens={alertKnockoutToken} />
          )}{" "}
          <span id="modalText">{shortText}</span>
        </Text>
        <ModalFooter>
          <Row alignmentHorizontal="right" widths="fit">
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
